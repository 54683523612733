export async function useLabels() {
  const labels = useState<Label[]>('labels', () => [])

  if (!labels.value || !labels.value.length) {
    const { data } = await fetchLabels()
    labels.value = data.value ?? []
  }

  // Are we using childlabels?
  const hasChildLabels = computed(() => labels.value.some(label => !!label.parentId))
  // Filter out the child labels, or return all if not using child/parents
  const childLabels = computed(() => hasChildLabels.value ? labels.value.filter(label => !!label.parentId) : labels.value)

  function getLabel(id: id) {
    return labels.value.find(label => label.id === id)
  }

  return {
    labels,
    childLabels,
    getLabel,
  }
}
